
<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i>
        <span class="ml-3">Employee License Expiration</span>
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="downloadCSV"
          ><span><i class="material-icons">add</i> Download CSV</span></a
        >
      </span>
    </div>

    <div class="container mt-10" style="background-color: #ffffff">
      <div class="mr-10 float-right">
        <div class="mt-5">
          <input
            type="month"
            id="example-monthpicker"
            v-model="value"
            :placeholder="placeholderText"
            class="mb-2 underline-input" 
            min="2023-03" 
          />
        </div>
      </div>

      <b-table
        class="table table-bordered"
        show-empty
        :items="filteredItems"
        select-mode="single"
        :fields="fields"
        :per-page="0"
        selectable
        striped
        sort-by="lastName"
        hover
      >
        <template #cell(licenceExpirationDate)="row">
          {{ row.item.licenceExpirationDate.split("T")[0] }}
        </template>
      </b-table>
    </div>

    <div
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular
        v-if="isfetching"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      value: "",
      isfetching: false,
      ScheduleDate: "",
      date: null,
      modalOpen: false,
      fields: [
        {
          key: "employeeName",
          label: "Employee Name",
        },
        {
          key: "licenceExpirationDate",
          label: "Expiration Date",
        },
        {
          key: "documentName",
          label: "Document Name",
        },
      ],
    };
  },

  mounted() {
  if (this.value) {
    const selectedMonth = new Date(this.value).getMonth() + 1;
    const selectedYear = new Date(this.value).getFullYear();
    this.$store.dispatch("getEmployeeExpirationReport", {
      filterMonth: selectedMonth,
      filterYear: selectedYear,
    });
  } else {
    this.$store.dispatch("getEmployeeExpirationReport");
  }
},
computed: {
  ...mapGetters(["GatEmployeeExpiration"]),
  filteredItems() {
    if (this.value) { 
      const selectedMonth = new Date(this.value).getMonth() + 1; 
      const selectedYear = new Date(this.value).getFullYear();
      return this.GatEmployeeExpiration.filter(item => {
        const itemMonth = new Date(item.licenceExpirationDate).getMonth() + 1;
        const itemYear = new Date(item.licenceExpirationDate).getFullYear();
        return selectedMonth === itemMonth && selectedYear === itemYear;
      });
    } else {
      return this.GatEmployeeExpiration; 
    }
  },
  placeholderText() {
    return this.value ? '' : 'Select month';
  }
},
methods: {
  downloadCSV() {
    var filename = "ExpirationReport.csv";
    var data = [];
    var header = "";

    header =  `"Employee Name","Document Name","Expiration Date"`;
    data.push(header);

    this.GatEmployeeExpiration.forEach((ele) => {
      var row = `"${ele.employeeName}","${ele.documentName}","${ele.licenceExpirationDate.split("T")[0]}"`;
      data.push(row);
    });

    this.downloadCSVFile(data.join("\n"), filename);
  },
  downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  },
},
};
</script>

  
  <style scoped>
.underline-input {
  border: 1px solid rgb(49, 49, 49);
  border-radius: 3px;
  padding: 2px; 
}

label {
  display: block;
  margin-bottom: 5px;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 90px;
  background-color: #ffffff;
}
.panel-body {
  background-color: #ffffff !important;
  padding: 10px;
  margin-top: 20px;
}
strong {
  padding-top: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.v-data-table tbody tr:nth-child(even) {
  background-color: #220c0c;
}

v-data-table tbody tr:hover {
  background-color: rgb(0, 0, 0);
}
.spanstar {
  right: 10px;
  top: 10px;
}
.rsmItem a.rsmLink {
  background: #fff;
  padding: 7px;
  border: 1px solid #ddd;
  margin: 7px;
  display: block;
  box-shadow: 0 3px 0 #ddd;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  border-radius: 5px;
  position: relative;
}
.rsmItem a.rsmLink:hover {
  background: #f8f8f8;
  box-shadow: 0 3px 0 #0284d0;
}
.org-checked {
  color: orange;
}
.org-unchecked {
  color: lightgray;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0b0f0e7a;
  color: white;
}
/*.tableheader{
      padding-left: 2pt;
      padding-top: 2pt;
      padding-right: 2pt;
      padding-bottom: 2pt;
      border-bottom: 1pt solid #d3d3d3;
      border-left: 1pt solid #d3d3d3;
      border-right: 1pt solid #d3d3d3;
      border-top: 1pt solid #d3d3d3;
      background-color: rgb(220,220,220);
      vertical-align: middle;
      text-align: left;
      direction: ltr;
      writing-mode: lr-tb;
      min-width: 42.64mm;
      WIDTH: 44.75mm;
  } */
</style>
  